// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": function componentNodeModulesGatsbyPluginOfflineAppShellJs() {
    return import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js"
    /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */
    );
  },
  "component---src-pages-404-js": function componentSrcPages404Js() {
    return import("/opt/build/repo/src/pages/404.js"
    /* webpackChunkName: "component---src-pages-404-js" */
    );
  },
  "component---src-pages-blog-index-js": function componentSrcPagesBlogIndexJs() {
    return import("/opt/build/repo/src/pages/blog/index.js"
    /* webpackChunkName: "component---src-pages-blog-index-js" */
    );
  },
  "component---src-pages-index-js": function componentSrcPagesIndexJs() {
    return import("/opt/build/repo/src/pages/index.js"
    /* webpackChunkName: "component---src-pages-index-js" */
    );
  },
  "component---src-pages-projects-js": function componentSrcPagesProjectsJs() {
    return import("/opt/build/repo/src/pages/projects.js"
    /* webpackChunkName: "component---src-pages-projects-js" */
    );
  },
  "component---src-pages-blog-misconceptions-about-react-mdx": function componentSrcPagesBlogMisconceptionsAboutReactMdx() {
    return import("/opt/build/repo/src/pages/blog/misconceptions-about-react.mdx"
    /* webpackChunkName: "component---src-pages-blog-misconceptions-about-react-mdx" */
    );
  },
  "component---src-pages-blog-write-better-emails-with-react-and-postonents-mdx": function componentSrcPagesBlogWriteBetterEmailsWithReactAndPostonentsMdx() {
    return import("/opt/build/repo/src/pages/blog/write-better-emails-with-react-and-postonents.mdx"
    /* webpackChunkName: "component---src-pages-blog-write-better-emails-with-react-and-postonents-mdx" */
    );
  },
  "component---src-pages-blog-why-we-use-css-in-js-mdx": function componentSrcPagesBlogWhyWeUseCssInJsMdx() {
    return import("/opt/build/repo/src/pages/blog/why-we-use-css-in-js.mdx"
    /* webpackChunkName: "component---src-pages-blog-why-we-use-css-in-js-mdx" */
    );
  }
};