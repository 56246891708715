import "core-js/modules/es6.object.assign";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
var React = require('react');

var Layout = require('./src/components/Layout').default;

var Navigation = require('./src/components/Navigation').default;

var ScrollProvider = require('./src/context/ScrollContext').ScrollProvider;

export var wrapPageElement = function wrapPageElement(_ref) {
  var element = _ref.element,
      props = _ref.props;
  return ___EmotionJSX(ScrollProvider, null, ___EmotionJSX(Layout, _extends({
    id: "page-main"
  }, props), ___EmotionJSX(Navigation, null), element));
};