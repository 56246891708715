module.exports = [{
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
  options: {
    "plugins": [],
    "defaultLayouts": {
      "default": "/opt/build/repo/src/components/PostLayout/index.js"
    },
    "gatsbyRemarkPlugins": [{
      "resolve": "gatsby-remark-images",
      "options": {
        "linkImagesToOriginal": false,
        "maxWidth": 900
      }
    }]
  }
}, {
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/opt/build/repo/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];