import { jsx as ___EmotionJSX } from "@emotion/core";
import React, { useContext } from 'react';
import ScrollContext from '../../context/ScrollContext';
import MyLogo from '../../images/logo.png';
import { Header, Nav, NavLink, HomeLink, Logo } from './styles';

var Navigation = function Navigation() {
  var _useContext = useContext(ScrollContext),
      _useContext$scrollTop = _useContext.scrollTop,
      scrollTop = _useContext$scrollTop === void 0 ? 0 : _useContext$scrollTop;

  return ___EmotionJSX(Header, {
    isScrolling: scrollTop > 300
  }, ___EmotionJSX(HomeLink, {
    title: "Go to Homepage",
    to: "/"
  }, ___EmotionJSX(Logo, {
    src: MyLogo,
    alt: "Osamah Aldoaiss"
  })), ___EmotionJSX(Nav, null, ___EmotionJSX(NavLink, {
    to: "/projects"
  }, "PROJECTS"), ___EmotionJSX(NavLink, {
    to: "/blog"
  }, "BLOG")));
};

export default Navigation;