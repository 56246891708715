import { jsx as ___EmotionJSX } from "@emotion/core";
import React, { createContext, useState, useLayoutEffect } from 'react';
export var defaultLayout = {
  scrollHeight: 0,
  scrollTop: 0
};
var ScrollContext = createContext(defaultLayout);
export var ScrollProvider = function ScrollProvider(_ref) {
  var children = _ref.children;

  var _useState = useState(0),
      scrollTop = _useState[0],
      setScrollTop = _useState[1];

  var _useState2 = useState(0),
      scrollHeight = _useState2[0],
      setScrollHeight = _useState2[1];

  useLayoutEffect(function () {
    var handleScroll = function handleScroll(event) {
      setScrollTop(event.target.scrollingElement.scrollTop || 0);
    };

    document.addEventListener('scroll', handleScroll);
    setScrollHeight(document.getElementById('page-main').scrollHeight);
    return function () {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return ___EmotionJSX(ScrollContext.Provider, {
    value: {
      scrollHeight: scrollHeight,
      scrollTop: scrollTop
    }
  }, children);
};
export default ScrollContext;